module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.10.0_gatsby-plugin-sharp@5.10.0_gatsby@5.10.0/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"withWebp":true,"withAvif":true,"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.10.0_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.1.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
